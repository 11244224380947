<template>
  <div class="absolute" :class="widgetPosition">
    <!-- Main WhatsApp Button -->
     
    <button
      class="whatsapp-button bg-[#45ba12] flex text-white px-2 py-2 rounded-full cursor-pointer text-lg"
      @click="togglePopup"
    >
    <svg xmlns="http://www.w3.org/2000/svg" height="2rem" fill="white" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" class="wa-messenger-svg-whatsapp wh-svg-icon"><path d=" M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z" fill-rule="evenodd"></path></svg>
      <span v-if="widgetBubbleType == 'expanded_bubble'"> Whatsapp </span>
    </button>

    <!-- Pop-up that appears on button click -->
    <div v-if="isPopupVisible" class="popup-container absolute bottom-16 w-72 bg-[#e5ddd5] shadow-lg rounded-lg" :class="widgetPosition == 'bottom-left'? 'left-5' : 'right-5'">
      <div class="popup-header flex items-center rounded-t-lg justify-between p-2" :style="{ backgroundColor: color }">
        <div class="ml-2">
          <span class="service-title font-bold text-sm">{{ header }}</span>
          <span class="service-sub-title block text-xs">{{ subHeader }}</span>
        </div>
        <button class="close-button text-black-600 text-xl" @click="togglePopup">X</button>
      </div>

      <div class="popup-body p-4">
        <div class="popup-input bg-white flex justify-right rounded-lg p-4 w-[80%] text-base text-black-700">
          <div v-html="formattedMessage"></div>
        </div>
      </div>
      <div class="rounded-b-lg p-2 bg-white text-center">
        <button
          class="whatsapp-popup-button w-full bg-green-500 text-white px-4 py-2 rounded-full cursor-pointer text-lg"
          @click="openWhatsApp"
          :style="{ backgroundColor: color }"
        >
          {{ whatsappButtonText }}
        </button>
        <a href="https://social-bot.io" target="_blank" class="text-[#96979C] text-xs text-gray-500 opacity-90 no-underline">Social Bot</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    formattedMessage() {
      // Replace '\n' with '<br>' and return the result as HTML
      return this.message.replace(/\n/g, "<br>");
    },
  },
  props: {
    header: {
      type: String,
      default: "خدمة عملاء مودة للسلامة",
    },
    subHeader: {
      type: String,
      default: "مرحبًا بك",
    },
    message: {
      type: String,
      default: "كيف أقدر أساعدك؟",
    },
    color: {
      type: String,
      default: "#25d366", // Default WhatsApp green
    },
    logo: {
      type: String,
      default: "https://example.com/default-logo.png",
    },
    whatsappButtonText: {
      type: String,
      default: "Send Message",
    },
    widgetPosition: {
      type: String,
      default: "bottom-right",
    },
    widgetBubbleType: {
      type: String,
      default: "standard", // Could be used for different bubble styles, if needed
    },
    whatsappNumber: {
      type: String,
      default: "+12345567"
    }
  },
  data() {
    return {
      isPopupVisible: true,
    };
  },
  methods: {
    togglePopup() {
      this.isPopupVisible = !this.isPopupVisible;
    },
    openWhatsApp() {
      const encodedMessage = encodeURIComponent(this.message);

      window.open(`https://wa.me/${this.whatsappNumber}?text=${encodedMessage}`, "_blank");
    },

  },
};
</script>

<style scoped>
.bottom-right {
  bottom: 20px;
  right: 20px;
}

.bottom-left {
  bottom: 20px;
  left: 20px;
}
</style>
