<script>
import LoadingState from 'dashboard/components/widgets/LoadingState.vue';
import { mapGetters } from 'vuex';
import AccountAPI from '../../../../../api/account';

export default {
  components: { LoadingState },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      iframeLoading: true,
      iframeURL: ''
    };
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
      currentAccountId: 'getCurrentAccountId',
      getAccount: 'accounts/getAccount'
    }),
    currentAccount() {
      return this.getAccount(this.currentAccountId) || {};
    },
  },
  mounted() {
    window.onmessage = e => {
      if (
        typeof e.data !== 'string' ||
        !e.data.startsWith('chatwoot-csml-editor:update')
      ) {
        return;
      }
      const csmlContent = e.data.replace('chatwoot-csml-editor:update', '');
      this.$emit('update:modelValue', csmlContent);
    };
    this.setIframeURL()
  },
  methods: {
    onEditorLoad() {
      const frameElement = document.getElementById(`csml-editor--frame`);
      const eventData = {
        event: 'editorContext',
        data: this.modelValue || '',
      };
      frameElement.contentWindow.postMessage(JSON.stringify(eventData), '*');
      this.iframeLoading = false;
    },
    async setIframeURL() {
      const key = await this.getRandomKey();
      this.iframeURL = 
        this.globalConfig.csmlEditorHost +  '?dbidentification=' + key     
    },
    async getRandomKey() {
      const response = await AccountAPI.getRandomKey()
      return response.data.account_random_key
    }
  },
};
</script>

<template>
  <div class="csml-editor--container">
    <LoadingState
      v-if="iframeLoading"
      :message="$t('AGENT_BOTS.LOADING_EDITOR')"
      class="dashboard-app_loading-container"
    />
    <iframe v-if="currentAccount.csml_bot_editor == 'default'" allow="microphone;" id="csml-editor--frame" :src="iframeURL" @load="onEditorLoad" />
    <iframe
      v-else
      id="csml-editor--frame"
      :src="'https://bot-editor.social-bot.io'"
      @load="onEditorLoad"
    />
  </div>
</template>

<style scoped>
#csml-editor--frame {
  border: 0;
  width: 100%;
  height: 100%;
}
</style>
