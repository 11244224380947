import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import LinksAPI from '../../api/linkGenerator';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
  },
  totalPages: 0,
  totalCount: 0, // Corrected getTotalCount to totalCount to match state
};

export const getters = {
  getLinks(_state) {
    return _state.records;
  },
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getLinksOnSidebar(_state) {
    return _state.records
      .filter(record => record.show_on_sidebar)
      .sort((a, b) => a.title.localeCompare(b.title));
  },
  getTotalPages(_state) {
    return _state.totalPages;
  },
  getTotalCount(_state) {
    return _state.totalCount;
  },
};

export const actions = {
  get: async function getLinks({ commit }, { page = 1 } = {}) {
    commit(types.SET_LINK_UI_FLAG, { isFetching: true });
    try {
      const response = await LinksAPI.get({ page });
  
      // Commit the custom links, total pages, and total count
      commit(types.SET_LINKS, response.data.custom_links);
      commit(types.SET_LINKS_TOTAL_PAGES, response.data.total_pages);
      commit(types.SET_TOTAL_LINKS, response.data.total_count);
    } catch (error) {
      // Handle error if needed
      console.error('Error fetching links:', error);
    } finally {
      commit(types.SET_LINK_UI_FLAG, { isFetching: false });
    }
  },

  create: async function createLinks({ commit }, cannedObj) {
    commit(types.SET_LINK_UI_FLAG, { isCreating: true });
    try {
      const response = await LinksAPI.create({ custom_link: { ...cannedObj } });
      commit(types.ADD_LINK, response.data);
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      throw new Error(errorMessage);
    } finally {
      commit(types.SET_LINK_UI_FLAG, { isCreating: false });
    }
  },

  update: async function updateLinks({ commit }, { id, ...updateObj }) {
    commit(types.SET_LINK_UI_FLAG, { isUpdating: true });
    try {
      const response = await LinksAPI.update(id, { custom_link: { ...updateObj } });
      commit(types.EDIT_LINK, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_LINK_UI_FLAG, { isUpdating: false });
    }
  },

  delete: async function deleteLinks({ commit }, id) {
    commit(types.SET_LINK_UI_FLAG, { isDeleting: true });
    try {
      await LinksAPI.delete(id);
      commit(types.DELETE_LINK, id);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_LINK_UI_FLAG, { isDeleting: false });
    }
  },
};

export const mutations = {
  [types.SET_LINK_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.SET_LINKS]: MutationHelpers.set,
  [types.ADD_LINK]: MutationHelpers.create,
  [types.EDIT_LINK]: MutationHelpers.update,
  [types.DELETE_LINK]: MutationHelpers.destroy,

  // New mutations for total pages and total links
  [types.SET_LINKS_TOTAL_PAGES](_state, totalPages) {
    _state.totalPages = totalPages;
  },
  [types.SET_TOTAL_LINKS](_state, totalCount) {
    _state.totalCount = totalCount;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
