<template>
  <div>
    <div class="flex widget-builder-container p-12">
      <div class="settings-container w-100 lg:w-[40%]">
        <div class="settings-content">
          <form @submit.prevent="updateWidget">
            <woot-input
              v-model.trim="header"
              :label="$t('WHATSAPP_WIDGET.HEADER_LABEL')"
              :placeholder="$t('WHATSAPP_WIDGET.HEADER_PLACEHOLDER')"
            />
            <woot-input
              v-model.trim="subheader"
              :label="$t('WHATSAPP_WIDGET.SUBHEADER_LABEL')"
              :placeholder="$t('WHATSAPP_WIDGET.SUBHEADER_PLACEHOLDER')"
            />

            <woot-input
              v-model.trim="buttonText"
              :label="$t('WHATSAPP_WIDGET.BUTTON_TEXT_LABEL')"
              :placeholder="$t('WHATSAPP_WIDGET.BUTTON_TEXT_PLACEHOLDER')"
            />

            <label>
              {{ $t('WHATSAPP_WIDGET.WIDGET_COLOR_LABEL') }}
              <woot-color-picker v-model="color" />
            </label>

            <input-radio-group
              name="widget-bubble-position"
              :label="$t('WHATSAPP_WIDGET.WIDGET_BUBBLE_POSITION_LABEL')"
              :items="widgetBubblePositions"
              :action="handleWidgetBubblePositionChange"
            />

            <input-radio-group
              name="widget-bubble-type"
              :label="$t('WHATSAPP_WIDGET.WIDGET_BUBBLE_TYPE_LABEL')"
              :items="widgetBubbleTypes"
              :action="handleWidgetBubbleTypeChange"
            />

            <label for="">
              {{ $t('WHATSAPP_WIDGET.MESSAGE_LABEL') }}
              <textarea
                v-model="message"
                rows="3"
                type="text"
              />
            </label>
            <woot-submit-button
              class="mt-4"
              button-text="Submit"
              :loading="uiFlags.isUpdating"
              :disabled="uiFlags.isUpdating"
            />
          </form>
        </div>
      </div>
      <div class="widget-container w-100 lg:w-3/5">
        <input-radio-group
          name="widget-view-options"
          :items="getWidgetViewOptions"
          :action="handleWidgetViewChange"
          :style="{ 'text-align': 'center' }"
        />
        <div v-if="isWidgetPreview" class="widget-preview relative h-[20rem]">
          <whatsapp-widget 
            :header="header"
            :sub-header="subheader"
            :color="color"
            :widget-position="widgetBubblePosition"
            :widget-bubble-type="widgetBubbleType"
            :message="message"
            :whatsapp-number="inbox.phone_number"
            :whatsapp-button-text="buttonText"
          />
        </div>
        <div v-else class="widget-script">
          <woot-code :script="widgetScript" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputRadioGroup from '../components/InputRadioGroup.vue';
import TextArea from 'shared/components/TextArea.vue';
import WhatsappWidget from './WhatsappWidget.vue';
import { useAlert } from 'dashboard/composables';
import InboxesAPI from '../../../../../api/inboxes'




export default {
  props: {
    inbox: {
      type: Object,
      default: () => {},
    }
  },
  components: {
		InputRadioGroup,
		TextArea,
    WhatsappWidget
	},
	computed: {
		getWidgetViewOptions() {
      return [
        {
          id: 'preview',
          title: this.$t(
            'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_VIEW_OPTION.PREVIEW'
          ),
          checked: true,
        },
        {
          id: 'script',
          title: this.$t(
            'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_VIEW_OPTION.SCRIPT'
          ),
          checked: false,
        },
      ];
    },
    widgetScript() {
      var script = `
        <script>

        window.whatsappWidgetSetting = {
          header: "${this.header}",
          subHeader: "${this.subheader}",
          color: "${this.color}",
          whatsappButtonText: "${this.buttonText}",
          widgetPosition: "${this.widgetBubblePosition}",
          widgetBubbleType: "${this.widgetBubbleType}",
          message: "${this.message.replace(/\n/g, '\\n')}",
          whatsappNumber: "${this.inbox.phone_number}"
        };

        // Dynamically load the WhatsApp SDK from the server
       <\/script>
       <script src='${window.chatwootConfig.hostURL}/packs/js/whatsappsdk.js' defer ><\/script>          
      `

      return script
    }
	},
  mounted() {
    this.setDefaults()
  },
  data() {
    return {
      isWidgetPreview: true,
      header: '',
      subheader: '',
      color: '#000', 
      widgetBubblePosition: 'bottom-right', // Default widget position
      widgetBubbleType: 'standard', // Default widget bubble type
      uiFlags: {
        isUpdating: false,
      },
			widgetBubblePositions: [
        {
          id: 'bottom-left',
          title: this.$t(
            'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_BUBBLE_POSITION.LEFT'
          ),
          checked: false,
        },
        {
          id: 'bottom-right',
          title: this.$t(
            'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_BUBBLE_POSITION.RIGHT'
          ),
          checked: true,
        },
      ],
      widgetBubbleTypes: [
        {
          id: 'standard',
          title: this.$t(
            'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_BUBBLE_TYPE.STANDARD'
          ),
          checked: true,
        },
        {
          id: 'expanded_bubble',
          title: this.$t(
            'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_BUBBLE_TYPE.EXPANDED_BUBBLE'
          ),
          checked: false,
        },
      ],
      buttonText: "Send Message",
			message: "",
    };
  },
  methods: {
    async updateWidget() {
      const payload = {
        header: this.header,
        subHeader: this.subheader,
        color: this.color,
        whatsappButtonText: this.buttonText,
        widgetPosition: this.widgetBubblePosition,
        widgetBubbleType: this.widgetBubbleType,
        message: this.message,
        whatsappNumber: this.inbox.phone_number
      };
      try {
        this.uiFlags.isUpdating = true;
        const response = await InboxesAPI.updateWhatsappWidget(this.inbox.id, payload)
        console.log(response)
      } catch (error) {
        console.log(error)
        useAlert("error")
      }
      this.uiFlags.isUpdating = false

    },
    async saveWidgetSettings() {
      // Your API call logic
    },
    handleWidgetBubblePositionChange(newValue) {
      this.widgetBubblePosition = newValue.id
    },
    handleWidgetBubbleTypeChange(newValue) {
      this.widgetBubbleType = newValue.id
    },
		handleWidgetViewChange(item) {
      this.isWidgetPreview = item.id === 'preview';
    },
    copyScript() {
      const scriptContent = this.widgetScript; // Get the script content
      navigator.clipboard.writeText(scriptContent).then(() => {
        useAlert('Script copied to clipboard!');
      }).catch(err => {
        useAlert('Failed to copy script');
        console.error('Failed to copy script:', err);
      });
    },
    setDefaults() {
      console.log("set default")
      const widget = this.inbox.provider_config['widget']
      if ( widget == '' || widget == undefined) return
      const {
        header,
        subHeader,
        color,
        widgetPosition,
        widgetBubbleType,
        whatsappButtonText,
        message,
      } = widget
      this.header = header || this.header
      this.subheader = subHeader || this.subheader
      this.color = color || this.color
      this.widgetBubblePosition = widgetPosition || this.widgetBubblePosition
      this.widgetBubbleType = widgetBubbleType || this.widgetBubbleType
      this.buttonText = whatsappButtonText || this.buttonText
      this.message = message || this.message
    },
  },

  watch: {
    inbox() {
      this.setDefaults();
    },
  }
};
</script>
