<template>
  <div class="relative w-full">
    <button
      v-if="banners && banners.length > 1"
      @click="prevBanner"
      class="absolute left-0 top-0 h-full px-4 flex items-center justify-center text-white bg-blue-500 hover:bg-blue-700 z-10"
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-6 w-6">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
      </svg>
    </button>
    <button
      v-if="banners && banners.length > 1"
      @click="nextBanner"
      class="absolute right-0 top-0 h-full px-4 flex items-center justify-center text-white bg-blue-500 hover:bg-blue-700 z-10"
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-6 w-6">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
      </svg>

    </button>

    <!-- Banners -->
    <div v-for="(banner, index) in banners" :key="banner.id" class="w-full h-12" v-show="currentIndex === index">
      <banner
        color-scheme="alert"
        :banner-message="banner.title"
        :action-button-label="actionButtonMessage(banner)"
        :has-action-button="hasActionButton(banner)"
        @click="routeToBilling(banner)"
      />
    </div>
  </div>
</template>

<script>
import Banner from 'dashboard/components/ui/Banner.vue';
import { mapGetters } from 'vuex';
// import adminMixin from 'dashboard/mixins/isAdmin';
// import accountMixin from 'dashboard/mixins/account';

export default {
  components: { Banner },
  // mixins: [adminMixin, accountMixin],
  props: {
    banners: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
    }),
  },
  methods: {
    routeToBilling(banner) {
      if (banner.custom_attributes && banner.custom_attributes.buttons && banner.custom_attributes.buttons[0].url) {
        window.open(banner.custom_attributes.buttons[0].url, '_blank');
      } else {
        return false;
      }
    },

    hasActionButton(banner) {
      return !!(banner.custom_attributes && banner.custom_attributes.buttons && banner.custom_attributes.buttons[0].url);
    },

    actionButtonMessage(banner) {
      return banner.custom_attributes && banner.custom_attributes.buttons && banner.custom_attributes.buttons[0].innerText || '';
    },

    nextBanner() {
      this.currentIndex = (this.currentIndex + 1) % this.banners.length;
    },

    prevBanner() {
      this.currentIndex = (this.currentIndex + this.banners.length - 1) % this.banners.length;
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
