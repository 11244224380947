<template>
	<div class="flex bg-slate-25 dark:bg-slate-900">
		<div class="bg-white dark:bg-slate-800 w-[75%]">
			<div class="m-2">
				<div class="input-group-field row  align-items-center">
					<div class="mb-2">
						<div class="font-bold">{{$t('WHATSAPP_PROFILE.PROFILE_PICTURE.LABEL')}}</div>
						<div class="text-sm">{{$t('WHATSAPP_PROFILE.PROFILE_PICTURE.DESCRIPTION')}}</div>
					</div>
						<input
							id="file"
							ref="file"
							type="file"
							accept="image/png, image/jpeg, image/gif"
							@change="handleImageUpload"
						/>
						<slot />
				</div>
				
				<div class="input-group-field">
					<label for="name" >
						{{ $t("WHATSAPP_PROFILE.ABOUT.LABEL") }}
						<input
							v-model.trim="profile.about"
							name="name"
							type="text"
							maxlength="139"
							:placeholder="$t('WHATSAPP_PROFILE.ABOUT.PLACEHOLDER')"
						/>
					</label>
				</div>
				<div class="input-group-field">
					<label for="name !font-bold" >
						{{ $t("WHATSAPP_PROFILE.CATEGORY.LABEL") }}
					</label>
						<select v-model="profile.vertical">
							<option v-for="option in categories" :key="option.value" :value="option.value">
								{{ option.name }}
							</option>
						</select>
				</div>
				<div class="mb-2">
					<div class="font-bold">{{$t('WHATSAPP_PROFILE.DESCRIPTION.LABEL')}}</div>
					<div class="text-sm">{{$t('WHATSAPP_PROFILE.DESCRIPTION_HEADING.DESCRIPTION')}}</div>
				</div>
				<div class="input-group-field">
					<label for="name" >
						{{ $t('WHATSAPP_PROFILE.DESCRIPTION.LABEL') }}
						<input
							v-model.trim="profile.description"
							name="name"
							type="text"
							maxlength="256"
							:placeholder="$t('WHATSAPP_PROFILE.DESCRIPTION.PLACEHOLDER')"
						/>
					</label>
				</div>
				<div class="mb-2">
					<div class="font-bold">{{$t('WHATSAPP_PROFILE.ADDRESS_INFORMATION.HEADING')}}</div>
					<div class="text-sm">{{$t('WHATSAPP_PROFILE.ADDRESS_INFORMATION.DESCRIPTION')}}</div>
				</div>
				<div class="input-group-field">
					<label for="name" >
						{{ $t("WHATSAPP_PROFILE.ADDRESS.LABEL") }}
						<input
							v-model.trim="profile.address"
							name="name"
							type="text"
							maxlength="256"
							:placeholder="$t('WHATSAPP_PROFILE.ADDRESS.PLACEHOLDER')"
						/>
					</label>
				</div>
				<div class="input-group-field">
					<label for="name" >
						{{ $t("WHATSAPP_PROFILE.EMAIL.LABEL") }}
						<input
							v-model.trim="profile.email"
							name="name"
							type="email"
							maxlength="138"
							:placeholder="$t('WHATSAPP_PROFILE.EMAIL.PLACEHOLDER')"
						/>
					</label>
				</div>
				<div class="input-group-field">
					<label for="name" >
						{{ $t("WHATSAPP_PROFILE.WEBSITE.LABEL") }}
						<input
							v-model.trim="profile.websites[0]"
							name="name"
							type="text"
							maxlength="256"
							:placeholder="$t('WHATSAPP_PROFILE.WEBSITE.PLACEHOLDER')"
						/>
					</label>
				</div>
				<div class="input-group-field">
					<label for="name" >
						{{ $t("WHATSAPP_PROFILE.WEBSITE.LABEL") }}
						<input
							v-model.trim="profile.websites[1]"
							name="name"
							type="text"
							maxlength="256"
							:placeholder="$t('WHATSAPP_PROFILE.WEBSITE.PLACEHOLDER')"
						/>
					</label>
				</div>
			</div>
			<woot-submit-button
				:button-text="$t('EMAIL_TRANSCRIPT.SUBMIT')"
				class="m-2"
				@click="submitForm"
				:disabled="isDisabled"
				
			/>
		</div>
		<div class="flex column ml-2 bg-[#f0f2f5] dark:bg-[#111b21]">
			<div class="flex column flex-col">
				<div class="flex bg-white dark:bg-slate-800 w-full justify-center items-center font-bold min-h-[50px]">
					<span>Business Profile View</span>
				</div>
				<div class="flex column"> 
					<div class="min-h-0 w-full min-w-0 flex-col">
						<div class="w-full min-w-0 min-h-0 h-[200px] mb-2 flex relative flex-col-reverse bg-cover bg-center bg-[#29d4b9]" :style="{ backgroundImage: 'url(' + imageUrl + ')' }">
							<fluent-icon
								size="28"
								icon="arrow-left"
								class="arrow-left absolute top-[20px] left-[20px] text-white"
							/>
							<fluent-icon
								size="28"
								icon="menu"
								class="menu absolute top-[20px] right-[20px] text-white"
							/>
							<i class="absolute top-[20px] "></i>
							<div class="bg-dark-gradient h-1/2">
							</div>
						</div>
						<div class="p-5 mb-2 bg-white dark:bg-slate-800 flex text-sm text-black-900 dark:text-slate-25 leading-4 flex-col w-full min-w-0 min-h-0">
							<span class="_72_7"> Business Account </span>
						</div>
						<div class="p-5 mb-2 bg-white dark:bg-slate-800 flex text-sm text-black-900 dark:text-slate-25 leading-4 flex-col w-full min-w-0 min-h-0">
							<div class="py-2 flex break-words" v-if="profile.description">
								<fluent-icon
									size="15"
									icon="building-retail"
									class="building-retail text-slate-500"
								/>
								<span class="break-all ml-1">
									{{profile.description}}
								</span>
							</div>
							<div class="py-2 flex break-words" v-if="profile.vertical">
									<fluent-icon
										size="15"
										icon="tag"
										class="tag text-slate-500"
									/>
								<span class="break-all ml-1">
									{{category(profile.vertical)}}
								</span>
							</div>
							<div class="py-2 flex break-words text-[#128c7e]" v-if="profile.address">
								<fluent-icon
									size="15"
									icon="location"
									class="location text-slate-500"
								/>
								<span class="break-all ml-1">
									{{profile.address}}
								</span>
								
							</div>
							<div class="py-2 flex break-words text-[#128c7e]" v-if="profile.email">
								<fluent-icon
									size="15"
									icon="mail-inbox-all"
									class="mail-inbox-all text-slate-500"
								/>
								<span class="break-all ml-1">
									{{profile.email}}
								</span>
							</div>
							<div class="py-2 flex break-words text-[#128c7e]" v-if="profile.websites[0]">
								<fluent-icon
									size="15"
									icon="globe"
									class="globe text-slate-500"
								/>
								<span class="break-all ml-1">
									{{profile.websites[0]}}
								</span>
							</div>
							<div class="py-2 flex break-words text-[#128c7e]" v-if="profile.websites[1]">
								<fluent-icon
									size="15"
									icon="globe"
									class="globe text-slate-500"
								/>
								<span class="break-all ml-1">
									{{profile.websites[1]}}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import InboxesAPI from '../../../../../api/inboxes'
import { useAlert } from 'dashboard/composables';

export default {
	props: {
		inbox: {
      type: Object,
      default() {
        return {};
      },
    },
	},
	computed: {
		categories() {
			return(
				[
					{"value": "AUTO", "name": "Vehicle Services"},
					{"value": "BEAUTY", "name": "Beauty, cosmetic & personal care"},
					{"value": "APPAREL", "name": "Apparel & clothing"},
					{"value": "EDU", "name": "Education"},
					{"value": "ENTERTAIN", "name": "Arts & entertainment"},
					{"value": "EVENT_PLAN", "name": "Event planner"},
					{"value": "FINANCE", "name": "Finance"},
					{"value": "GROCERY", "name": "SuperMarket/convenience store"},
					{"value": "GOVT", "name": "Public and goverment services"},
					{"value": "HOTEL", "name": "Hotel"},
					{"value": "HEALTH", "name": "Medical & health"},
					{"value": "NONPROFIT", "name": "Non-profit organisation"},
					{"value": "PROF_SERVICES", "name": "Professional service"},
					{"value": "RETAIL", "name": "Shopping & retail"},
					{"value": "TRAVEL", "name": "Travel and transport"},
					{"value": "RESTAURANT", "name": "Restaurant"},
					{"value": "OTHER", "name": "Other"},
				]
			)
		}
	},
	data() {
		return {
			imageUrl: '',
			imageFile: '',
			isDisabled: true,
			profile: {
				description: '',
				about: '',
				address: '',
				email: '',
				websites: ['',''],
				vertical: '',
				messaging_product: 'whatsapp',
				
			}
		}
	},
	methods: {
		async handleImageUpload(event) {
      const [file] = event.target.files;
      this.imageFile = file;
      this.imageUrl = file? URL.createObjectURL(file) : '';
    },
		async submitForm() {
			this.isDisabled = true
      const response = await InboxesAPI.UpdateProfilePicture(this.inbox.id, this.imageFile, this.profile)
      if(response.data.message) {
        useAlert(this.$t('WHATSAPP_PROFILE.FORM.SUCCESSFUL'))
      } else {
        useAlert(response.data.error)
      }
			this.isDisabled = false
    },
		category(vertical){
			const category = this.categories.find(c => c.value === vertical);
    	return category ? category.name : 'Category not found';
		}
	},
	watch: {
		inbox: {
			handler(val){
				this.imageUrl = val.profile_picture_url
				this.profile.description = val.provider_config.profile?.description || ''
				this.profile.about = val.provider_config.profile?.about || ''
				this.profile.email = val.provider_config.profile?.email || ''
				this.profile.address = val.provider_config.profile?.address || ''
				this.profile.websites[0] = val.provider_config.profile?.websites[0] || ''
				this.profile.websites[1] = val.provider_config.profile?.websites[1] || ''
				// this.profile.vertical = val.provider_config.profile?.vertical || 'UNDEFINED'
			},
			deep: true,
			immediate: true, 
		},
		imageFile(newVal){
			if(newVal === undefined){
				this.imageUrl = this.inbox.profile_picture_url
			} else {
				if(this.profile.about) this.isDisabled = false
			}
		},
		profile: {
			handler(newVal){
				if(newVal.about == ''){
					this.isDisabled = true
				} else {
					this.isDisabled = false
				}
			},
			deep: true
		}
	}
}
</script>

<style scoped>
.profile-pic{
	border-radius: 100%;
	width: 100px;
	height: 100px;
	border: 1px solid grey;
}
.m-2{
	margin: 2rem;
}
.align-items-center {
	align-items: center;
}
.bg-dark-gradient{
	background: linear-gradient( to bottom, transparent 0%, rgba(0, 0, 0, .4) 100% );
}
._72_7 {
	word-wrap: break-word;
	word-break: break-all;
}
</style>
