<script>
import Logo from './Logo.vue';
import PrimaryNavItem from './PrimaryNavItem.vue';
import OptionsMenu from './OptionsMenu.vue';
import AgentDetails from './AgentDetails.vue';
import NotificationBell from './NotificationBell.vue';
import wootConstants from 'dashboard/constants/globals';
import { frontendURL } from 'dashboard/helper/URLHelper';
import { ACCOUNT_EVENTS } from '../../../helper/AnalyticsHelper/events';
import { useTrack } from 'dashboard/composables';
import AnnouncementModal from 'shared/components/AnnouncementModal.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    Logo,
    PrimaryNavItem,
    OptionsMenu,
    AgentDetails,
    NotificationBell,
    AnnouncementModal,
  },
  computed: {
    ...mapGetters({
      isDrawerOpen: 'navButton/isDrawerOpen',
    }),
  },
  props: {
    isACustomBrandedInstance: {
      type: Boolean,
      default: false,
    },
    logoSource: {
      type: String,
      default: '',
    },
    installationName: {
      type: String,
      default: '',
    },
    accountId: {
      type: Number,
      default: 0,
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    activeMenuItem: {
      type: String,
      default: '',
    },
    showAnnouncement: {
      type: Boolean,
      default: false
    },
    announcement: {
      type: Object,
      default: () => {},
    }
  },
  emits: ['toggleAccounts', 'openNotificationPanel', 'openKeyShortcutModal'],
  data() {
    return {
      helpDocsURL: wootConstants.DOCS_URL,
      showOptionsMenu: false,
      showAnnouncementModal: false
    };
  },
  methods: {
    frontendURL,
    toggleOptions() {
      this.showOptionsMenu = !this.showOptionsMenu;
    },
    toggleAccountModal() {
      this.$emit('toggleAccounts');
    },
    toggleSupportChatWindow() {
      window.$chatwoot.toggle();
    },
    openNotificationPanel() {
      useTrack(ACCOUNT_EVENTS.OPENED_NOTIFICATIONS);
      this.$emit('openNotificationPanel');
    },
    closeAnnouncementModal() {
      this.showAnnouncementModal = false
    },
    openAnnouncementModal() {
      this.$refs.announcementModal.showAnnouncementModal = true
      const expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 365);
      const expiryString = expiryDate.toUTCString();
      const announcementID = this.getCookieValue('lastClickedAnnouncementID')
      if((announcementID == undefined || announcementID < this.announcement.id) && this.announcement.id != null)
        document.cookie = "lastClickedAnnouncementID=" + this.announcement.id + ";path=/;expires=" + expiryString;
    },
    parsedDescription(string) {
      return string.replace(/\r\n/g, '<br>')
    },
    toggleDrawer() {
      this.isDrawerOpen = !this.isDrawerOpen;
    },
    getCookieValue(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
      return null;
    }
  },
  watch: {
    announcement() {
      if(this.announcement == null) return;
      const cookie = this.getCookieValue('lastClickedAnnouncementID')
      if(cookie == undefined || cookie < this.announcement.id || this.announcement.id === null) {
        this.openAnnouncementModal()
      }
    }
  }
};
</script>

<template>
  <div class="relative h-full">
    <div
      v-if="isDrawerOpen"
      class="inset-0 z-40 w-16 h-full bg-white dark:bg-slate-900 border-r border-slate-50 dark:border-slate-800/50 rtl:border-l rtl:border-r-0 flex flex-col justify-between transform transition-transform md:hidden"
    >
      <div class="flex flex-col items-center">
        <Logo
          :source="logoSource"
          :name="installationName"
          :account-id="accountId"
          class="m-4 mb-10"
        />
        <PrimaryNavItem
          v-for="menuItem in menuItems"
          :key="menuItem.toState"
          :icon="menuItem.icon"
          :name="menuItem.label"
          :to="menuItem.toState"
          :is-child-menu-active="menuItem.key === activeMenuItem"
        />
      </div>
      <div class="flex flex-col items-center justify-end pb-6">
        <a
          v-if="showAnnouncement"
          v-tooltip.right="`Show announcement`"
          class="text-slate-700 dark:text-slate-100 w-10 h-10 my-2 flex items-center justify-center rounded-lg hover:bg-slate-25 dark:hover:bg-slate-700 dark:hover:text-slate-100 hover:text-slate-600 relative"
          @click="openAnnouncementModal"
        >
          <fluent-icon icon="announcement" />
          <span class="sr-only">Show announcement</span>
        </a>
        <PrimaryNavItem
          icon="book-open-globe"
          name="DOCS"
          :open-in-new-page="true"
          :to="helpDocsURL"
        />
        <NotificationBell @open-notification-panel="openNotificationPanel" />
        <AgentDetails @toggle-menu="toggleOptions" />
        <OptionsMenu
          :show="showOptionsMenu"
          @toggle-accounts="toggleAccountModal"
          @show-support-chat-window="toggleSupportChatWindow"
          @key-shortcut-modal="$emit('key-shortcut-modal')"
          @close="toggleOptions"
        />
      </div>
    </div>
    <!-- Primary Navigation Bar for Desktop (Hidden on Mobile) -->
    <div
      class="flex flex-col justify-between w-16 h-full bg-white border-r dark:bg-slate-900 border-slate-50 dark:border-slate-800/50 rtl:border-l rtl:border-r-0  hidden md:flex"
    >
      <div class="flex flex-col items-center">
        <Logo
          :source="logoSource"
          :name="installationName"
          :account-id="accountId"
          class="m-4 mb-10"
        />
        <PrimaryNavItem
          v-for="menuItem in menuItems"
          :id="menuItem.key"
          :key="menuItem.toState"
          :icon="menuItem.icon"
          :name="menuItem.label"
          :to="menuItem.toState"
          :is-child-menu-active="menuItem.key === activeMenuItem"
        />
      </div>
      <div class="flex flex-col items-center justify-end pb-6">
        <a
          v-if="!isACustomBrandedInstance"
          v-tooltip.right="$t(`SIDEBAR.DOCS`)"
          :href="helpDocsURL"
          class="relative flex items-center justify-center w-10 h-10 my-2 rounded-lg text-slate-700 dark:text-slate-100 hover:bg-slate-25 dark:hover:bg-slate-700 dark:hover:text-slate-100 hover:text-slate-600"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          <fluent-icon icon="book-open-globe" />
          <span class="sr-only">{{ $t(`SIDEBAR.DOCS`) }}</span>
        </a>
        <a
          v-if="showAnnouncement"
          v-tooltip.right="`Show announcement`"
          class="text-slate-700 dark:text-slate-100 w-10 h-10 my-2 flex items-center justify-center rounded-lg hover:bg-slate-25 dark:hover:bg-slate-700 dark:hover:text-slate-100 hover:text-slate-600 relative"
          @click="openAnnouncementModal"
        >
          <fluent-icon icon="announcement" />
          <span class="sr-only">Show announcement</span>
        </a>
        <PrimaryNavItem
          icon="book-open-globe"
          name="DOCS"
          :open-in-new-page="true"
          :to="helpDocsURL"
        />
        <NotificationBell @open-notification-panel="openNotificationPanel" />
        <AgentDetails @toggle-menu="toggleOptions" />
        <OptionsMenu
          :show="showOptionsMenu"
          @toggle-accounts="toggleAccountModal"
          @show-support-chat-window="toggleSupportChatWindow"
          @open-key-shortcut-modal="$emit('openKeyShortcutModal')"
          @close="toggleOptions"
        />
      </div>
      <AnnouncementModal
        ref="announcementModal"
        :announcement="announcement"
        :show-announcement="showAnnouncement"
        :show-button="true"
      />
    </div>
  </div>
</template>
